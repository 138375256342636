import React from "react";
import { FormattedMessage } from "react-intl";
import Menu from '@mui/material/Menu';
import { IoMdMenu } from "react-icons/io";
import Fade from '@mui/material/Fade';
import { HashLink as Link } from 'react-router-hash-link';

// Components
import { Lang } from "../../widgets/Lang";

// Json
import items from '../../assets/json/routes.json'

// Images and icons
import Logo from "../../assets/images/ddh.svg";
import { IconButton } from "@mui/material";

// Styles
import '../../styles/NavBar/NavBar.css'

export const NavBar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header className='navbar'>
      <Link className="logo" to="/">
        <img src={Logo} alt="Logo DAO" />
      </Link>
      <nav>
        {items.map((item, index) => (
          <Link className="link" key={index} to={item.route}>
            <FormattedMessage
              id={item.name}
              defaultMessage="error"
            />
          </Link>
        ))}
      </nav>
      <Link className="button" to="/Dapp">
        <FormattedMessage
          id="menu.dapp"
          defaultMessage="error"
        />
      </Link>
      <Lang className="lang" />
      <div className="icon_menu">
        <IconButton
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <IoMdMenu />
        </IconButton>
      </div>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {items.map((item, index) => (
          <Link className="link" key={index} to={item.route}>
            <FormattedMessage
              id={item.name}
              defaultMessage="error"
            />
          </Link>
        ))}
        <div className="space" />
        <Link className="button_cel" to="/Dapp">
          <FormattedMessage
            id="menu.dapp"
            defaultMessage="error"
          />
        </Link>
      </Menu>
    </header>
  )
}

// 1.- ccm
import CCM1 from "../../assets/images/tutorials/ccm/CCM1.png";
import CCM2 from "../../assets/images/tutorials/ccm/CCM2.png";
import CCM3 from "../../assets/images/tutorials/ccm/CCM3.png";
import CCM4 from "../../assets/images/tutorials/ccm/CCM4.png";
import CCM5 from "../../assets/images/tutorials/ccm/CCM5.png";
import CCM6 from "../../assets/images/tutorials/ccm/CCM6.png";
import CCM7 from "../../assets/images/tutorials/ccm/CCM7.png";
import CCM8 from "../../assets/images/tutorials/ccm/CCM8.png";

// 2.- addbnb
import ADDBNB1 from "../../assets/images/tutorials/addbnb/ADDBNB1.png";
import ADDBNB2 from "../../assets/images/tutorials/addbnb/ADDBNB2.png";
import ADDBNB3 from "../../assets/images/tutorials/addbnb/ADDBNB3.png";
import ADDBNB4 from "../../assets/images/tutorials/addbnb/ADDBNB4.png";
import ADDBNB5 from "../../assets/images/tutorials/addbnb/ADDBNB5.png";
import ADDBNB6 from "../../assets/images/tutorials/addbnb/ADDBNB6.png";

// 3.- impusdt
import IMPUSDT1 from "../../assets/images/tutorials/impusdt/IMPUSDT0.png";
import IMPUSDT2 from "../../assets/images/tutorials/impusdt/IMPUSDT1.png";
import IMPUSDT3 from "../../assets/images/tutorials/impusdt/IMPUSDT2.png";
import IMPUSDT4 from "../../assets/images/tutorials/impusdt/IMPUSDT3.png";
import IMPUSDT5 from "../../assets/images/tutorials/impusdt/IMPUSDT4.png";

// 4.- hbbnb
import HBBNB1 from "../../assets/images/tutorials/hbbnb/HBBNB1.png";
import HBBNB2 from "../../assets/images/tutorials/hbbnb/HBBNB2.png";
import HBBNB3 from "../../assets/images/tutorials/hbbnb/HBBNB3.png";
import HBBNB4 from "../../assets/images/tutorials/hbbnb/HBBNB4.png";
import HBBNB5 from "../../assets/images/tutorials/hbbnb/HBBNB5.png";
import HBBNB6 from "../../assets/images/tutorials/hbbnb/HBBNB6.png";
import HBBNB7 from "../../assets/images/tutorials/hbbnb/HBBNB7.png";

// 5.- hcbu
import HCBU1 from "../../assets/images/tutorials/hcbu/HCBU1.png";
import HCBU2 from "../../assets/images/tutorials/hcbu/HCBU2.png";
import HCBU3 from "../../assets/images/tutorials/hcbu/HCBU3.png";
import HCBU4 from "../../assets/images/tutorials/hcbu/HCBU4.png";
import HCBU5 from "../../assets/images/tutorials/hcbu/HCBU5.png";
import HCBU6 from "../../assets/images/tutorials/hcbu/HCBU6.png";

// 6.- cid
import CID1 from "../../assets/images/tutorials/cid/CID1.png";
import CID2 from "../../assets/images/tutorials/cid/CID2.png";
import CID3 from "../../assets/images/tutorials/cid/CID3.png";
import CID4 from "../../assets/images/tutorials/cid/CID4.png";
import CID5 from "../../assets/images/tutorials/cid/CID5.png";
import CID6 from "../../assets/images/tutorials/cid/CID6.png";
import CID7 from "../../assets/images/tutorials/cid/CID7.png";
import CID8 from "../../assets/images/tutorials/cid/CID8.png";
import CID9 from "../../assets/images/tutorials/cid/CID9.png";
import CID10 from "../../assets/images/tutorials/cid/CID10.png";
import CID11 from "../../assets/images/tutorials/cid/CID12.png";
import CID12 from "../../assets/images/tutorials/cid/CID12.png";
import CID13 from "../../assets/images/tutorials/cid/CID13.png";

// 7.- add
import ADD1 from "../../assets/images/tutorials/add/ADD1.png";
import ADD2 from "../../assets/images/tutorials/add/ADD1.png";
import ADD3 from "../../assets/images/tutorials/add/ADD1.png";
import ADD4 from "../../assets/images/tutorials/add/ADD1.png";
import ADD5 from "../../assets/images/tutorials/add/ADD1.png";
import ADD6 from "../../assets/images/tutorials/add/ADD1.png";
import ADD7 from "../../assets/images/tutorials/add/ADD1.png";
import ADD8 from "../../assets/images/tutorials/add/ADD1.png";

const data = [
  // 1.- ccm
  {
    id: "ccm",
    title: ["tutorials.ccm.title_1", "tutorials.ccm.title_2"],
    text: [
      {
        img: [CCM1, CCM2],
        text: ["tutorials.ccm.text_1_1", "tutorials.ccm.text_1_2"]
      },
      {
        img: [CCM3],
        text: ["tutorials.ccm.text_2_1", "tutorials.ccm.text_2_2"]
      },
      {
        img: [CCM4],
        text: ["tutorials.ccm.text_3_1"]
      },
      {
        img: [CCM5],
        text: ["tutorials.ccm.text_4_1", "tutorials.ccm.text_4_2", "tutorials.ccm.text_4_3"]
      },
      {
        img: [CCM6],
        text: ["tutorials.ccm.text_5_1", "tutorials.ccm.text_5_2", "tutorials.ccm.text_5_3"]
      },
      {
        img: [CCM7],
        text: ["tutorials.ccm.text_6_1", "tutorials.ccm.text_6_2", "tutorials.ccm.text_6_3"]
      },
      {
        img: [CCM8],
        text: ["tutorials.ccm.text_7_1", "tutorials.ccm.text_7_2"]
      },
    ],
  },
  // 2.- addbnb
  {
    id: "addbnb",
    title: [
      "tutorials.addbnb.title_1",
      "tutorials.addbnb.title_2",
      "tutorials.addbnb.title_3"
    ],
    text: [
      {
        img: [ADDBNB1],
        text: [
          "tutorials.addbnb.text_1_1",
          "tutorials.addbnb.text_1_2"
        ]
      },
      {
        img: [ADDBNB2],
        text: [
          "tutorials.addbnb.text_2_1",
          "tutorials.addbnb.text_2_2"
        ]
      },
      {
        img: [ADDBNB3],
        text: [
          "tutorials.addbnb.text_3_1",
          "tutorials.addbnb.text_3_2"
        ]
      },
      {
        img: [ADDBNB4],
        text: [
          "tutorials.addbnb.text_4_1",
          "tutorials.addbnb.text_4_2",
          "tutorials.addbnb.text_4_3"
        ]
      },
      {
        img: [ADDBNB5],
        text: [
          "tutorials.addbnb.text_5_1",
          "tutorials.addbnb.text_5_2",
          "tutorials.addbnb.text_5_3"
        ]
      },
      {
        img: [ADDBNB6],
        text: [
          "tutorials.addbnb.text_6_1",
          "tutorials.addbnb.text_6_2",
          "tutorials.addbnb.text_6_3"
        ]
      },
    ],
  },
  // 3.- impusdt
  {
    id: "impusdt",
    title: [
      "tutorials.impusdt.title_1",
      "tutorials.impusdt.title_2",
      "tutorials.impusdt.title_3"
    ],
    text: [
      {
        img: [IMPUSDT1],
        text: [
          "tutorials.impusdt.text_1_1",
          "tutorials.impusdt.text_1_2"
        ]
      },
      {
        img: [IMPUSDT2],
        text: [
          "tutorials.impusdt.text_2_1",
          "tutorials.impusdt.text_2_2"
        ]
      },
      {
        img: [IMPUSDT3],
        text: [
          "tutorials.impusdt.text_3_1",
          "tutorials.impusdt.text_3_2"
        ]
      },
      {
        img: [IMPUSDT4],
        text: [
          "tutorials.impusdt.text_4_1",
          "tutorials.impusdt.text_4_2",
          "tutorials.impusdt.text_4_3"
        ]
      },
      {
        img: [IMPUSDT5],
        text: [
          "tutorials.impusdt.text_5_1",
          "tutorials.impusdt.text_5_2",
          "tutorials.impusdt.text_5_3"
        ]
      },
    ],
  },
  // 4.- hbbnb
  {
    id: "hbbnb",
    title: [
      "tutorials.hbbnb.title_1",
      "tutorials.hbbnb.title_2"
    ],
    text: [
      {
        img: [HBBNB1, HBBNB2],
        text: [
          "tutorials.hbbnb.text_1_1",
          "tutorials.hbbnb.text_1_2"
        ]
      },
      {
        img: [HBBNB3],
        text: [
          "tutorials.hbbnb.text_2_1"
        ]
      },
      {
        img: [HBBNB4],
        text: [
          "tutorials.hbbnb.text_3_1"
        ]
      },
      {
        img: [HBBNB5],
        text: [
          "tutorials.hbbnb.text_4_1"
        ]
      },
      {
        img: [HBBNB6],
        text: [
          "tutorials.hbbnb.text_5_1",
          "tutorials.hbbnb.text_5_2",
          "tutorials.hbbnb.text_5_3"
        ]
      },
      {
        img: [HBBNB7],
        text: [
          "tutorials.hbbnb.text_6_1"
        ]
      },
    ],
  },
  // 5.- hcbu
  {
    id: "hcbu",
    title: [
      "tutorials.hcbu.title_1",
      "tutorials.hcbu.title_2"
    ],
    text: [
      {
        img: [HCBU1],
        text: [
          "tutorials.hcbu.text_1_1",
          "tutorials.hcbu.text_1_2"
        ]
      },
      {
        img: [HCBU2],
        text: [
          "tutorials.hcbu.text_2_1"
        ]
      },
      {
        img: [HCBU3],
        text: [
          "tutorials.hcbu.text_3_1",
          "tutorials.hcbu.text_3_2"
        ]
      },
      {
        img: [HCBU4],
        text: [
          "tutorials.hcbu.text_4_1",
          "tutorials.hcbu.text_4_2"
        ]
      },
      {
        img: [HCBU5],
        text: [
          "tutorials.hcbu.text_5_1"
        ]
      },
      {
        img: [HCBU6],
        text: [
          "tutorials.hcbu.text_6_1",
          "tutorials.hcbu.text_6_2"
        ]
      },
    ],
  },
  // 6.- cid
  {
    id: "cid",
    title: [
      "tutorials.cid.title_1",
      "tutorials.cid.title_2"
    ],
    text: [
      {
        img: [CID1],
        text: [
          "tutorials.cid.text_1_1",
          "tutorials.cid.text_1_2"
        ]
      },
      {
        img: [CID2],
        text: [
          "tutorials.cid.text_2_1",
          "tutorials.cid.text_2_2",
          "tutorials.cid.text_2_3",
          "tutorials.cid.text_2_4",
          "tutorials.cid.text_2_5"
        ]
      },
      {
        img: [CID3],
        text: [
          "tutorials.cid.text_3_1",
          "tutorials.cid.text_3_2",
          "tutorials.cid.text_3_3",
          "tutorials.cid.text_3_4"
        ]
      },
      {
        img: [CID4],
        text: [
          "tutorials.cid.text_4_1",
          "tutorials.cid.text_4_2"
        ]
      },
      {
        img: [CID5],
        text: [
          "tutorials.cid.text_5_1",
          "tutorials.cid.text_5_2",
          "tutorials.cid.text_5_3",
          "tutorials.cid.text_5_4"
        ]
      },
      {
        img: [CID6],
        text: [
          "tutorials.cid.text_6_1",
          "tutorials.cid.text_6_2"
        ]
      },
      {
        img: [CID7],
        text: [
          "tutorials.cid.text_7_1",
          "tutorials.cid.text_7_2"
        ]
      },
      {
        img: [CID8, CID9],
        text: [
          "tutorials.cid.text_8_1",
          "tutorials.cid.text_8_2"
        ]
      },
      {
        img: [CID10, CID11],
        text: [
          "tutorials.cid.text_9_1",
          "tutorials.cid.text_9_2"
        ]
      },
      {
        img: [CID12, CID13],
        text: [
          "tutorials.cid.text_10_1",
          "tutorials.cid.text_10_2",
          "tutorials.cid.text_10_3"
        ]
      },
    ],
  },
  // 7.- add
  {
    id: "add",
    title: [
      "tutorials.add.title_1",
      "tutorials.add.title_2",
      "tutorials.add.title_3"
    ],
    text: [
      {
        img: [ADD1],
        text: [
          "tutorials.add.text_1_1",
          "tutorials.add.text_1_2",
          "tutorials.add.text_1_3",
          "tutorials.add.text_1_4"
        ]
      },
      {
        img: [ADD2, ADD3],
        text: [
          "tutorials.add.text_2_1",
          "tutorials.add.text_2_2"
        ]
      },
      {
        img: [ADD4, ADD5],
        text: [
          "tutorials.add.text_3_1",
          "tutorials.add.text_3_2",
          "tutorials.add.text_3_3",
          "tutorials.add.text_3_4"
        ]
      },
      {
        img: [ADD6, ADD7],
        text: [
          "tutorials.add.text_4_1",
          "tutorials.add.text_4_2"
        ]
      },
      {
        img: [ADD8],
        text: [
          "tutorials.add.text_5_1",
          "tutorials.add.text_5_2",
          "tutorials.add.text_5_3"
        ]
      },
    ],
  },
]

export default data;
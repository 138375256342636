import { useEffect, useState } from "react";
import { TrimWallet } from "../../widgets/Functions";
import { FormattedMessage } from "react-intl";

// Image
import LogoMetamask from "../../assets/images/metamask.svg";
import BNBLogo from "../../assets/images/bnb.svg";

// styles
import "../../styles/Dapp/Account.css";

const NETWORK_STATES = {
  MAINNET: "account.network.mainnet",
  INCORRECT: "account.network.incorrect",
};

export const Account = ({ account, stateNetwork, balance, usdtBalance, addTokenUSDT }) => {
  const [network, setNetwork] = useState(NETWORK_STATES.MAINNET);
  const trimmedAccount = TrimWallet(account);

  useEffect(() => {
    if (stateNetwork === 100) {
      setNetwork(NETWORK_STATES.MAINNET);
    } else {
      setNetwork(NETWORK_STATES.INCORRECT);
    }
  }, [stateNetwork, account]);

  return (
    <div className="account">
      <div className="account_balance">
        <p>{balance} BNB</p>
        <p>{usdtBalance} USDT</p>
        <button
          className="account_button"
          onClick={addTokenUSDT}
        >
          <FormattedMessage
            id="backdrop.addUsdt"
            defaultMessage="error"
          />
        </button>
      </div>
      <div className="wallets">
        <p className="wallets_bnb">
          <img src={BNBLogo} alt="Logo BNB" />
          <FormattedMessage
            id={network}
            defaultMessage="error"
          />
        </p>
        <p>
          <img src={LogoMetamask} alt="Logo metamask" />
          {trimmedAccount}
        </p>
      </div>
    </div>
  );
};

import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { NumberFormat, DatesPeriod36 } from '../../widgets/Functions';
import { FormattedMessage } from "react-intl";
import swal from 'sweetalert';


// Icons
import BNB from "../../assets/images/bnb.svg";
import DDD from "../../assets/images/ddd.svg";
import USDT from "../../assets/images/usdt.svg";

// Styles
import '../../styles/Dapp/Staking.css';

const min = Number(process.env.REACT_APP_MIN_CONTRIBUTIONS);

export const Staking = ({ addInvest, type }) => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState(0);
  const [period, setPeriod] = useState(0)
  const [datesPeriod, setDatesPeriod] = useState({
    start: "",
    end: ""
  });

  useState(
    () => {
      const { dateStartTemp, dateFinalDate, months } = DatesPeriod36();
      setDatesPeriod({
        start: dateStartTemp,
        end: dateFinalDate
      });
      setPeriod(months);
    }, []
  )

  const HandleAmount = (e) => {
    const inputString = e.target.value;
    const cleanedString = inputString.replace(/[^0-9.]/g, '');
    const number = parseFloat(cleanedString);
    if (isNaN(number)) setAmount(0);
    else setAmount(number);;
  };

  const HandleInvest = () => {
    if (type === "dapp") {
      if (amount < min)
        swal("Error!", `The minimum contribution amount is $${min} USDT.`, "error");
      else addInvest(period, amount)
    }
    else navigate("/Dapp")
  };

  return (
    <div className='staking'>
      <div>
        <label>
          <FormattedMessage
            id="staking.label.from"
            defaultMessage="error"
          />
          <span>
            <img src={BNB} alt="BNB Samart Chain" />
            BNB Chain
          </span>
        </label>
        <div className='staking_input'>
          <p>
            <FormattedMessage
              id="staking.send"
              defaultMessage="error"
            />
          </p>
          <div className='staking_mount'>
            <input
              type='text'
              value={`$${NumberFormat(amount, 0)}`}
              onChange={HandleAmount}
            />
            <p>
              <img src={USDT} alt='USDT' />
              USDT
            </p>
          </div>
        </div>
      </div>
      <div>
        <label>
          <FormattedMessage
            id="staking.label.to"
            defaultMessage="error"
          />
          <span>
            <img src={DDD} alt="BNB Samart Chain" />
            Desire Dao
          </span>
        </label>
        <div className='staking_input'>
          <p>
            <FormattedMessage
              id="staking.onepay"
              defaultMessage="error"
            />
          </p>
          <div className='staking_mount'>
            <input
              readOnly
              value={`$${NumberFormat(amount * 1.5)} - $${NumberFormat(amount * 3)}`}
            />
            <p>
              <img src={USDT} alt='USDT' />
              USDT
            </p>
          </div>
          <div className='staking_dates'>
            <p>
              <FormattedMessage
                id="staking.start"
                defaultMessage="error"
              />
              <span>
                {datesPeriod.start}
              </span>
            </p>
            <p>
              <FormattedMessage
                id="staking.end"
                defaultMessage="error"
              />
              <span>
                {datesPeriod.end}
              </span>
            </p>
          </div>
        </div>
      </div>
      <button onClick={HandleInvest}>
        <FormattedMessage
          id="staking.button"
          defaultMessage="error"
        />
      </button>
    </div>
  )
}

import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import { Staking } from '../Dapp/Staking';

// Assets
import Coins from '../../assets/images/cryptos2.png'

// Styles
import "../../styles/Home/FrontPage.css";

export const FrontPage = () => {

  return (
    <section className='frontpage_main'>
      <div style={{ height: "90%" }}>
        <Staking />
      </div>
      <div>
        <h3 className='frontpage_subtitle'>
          <FormattedMessage
            id="frontpage.subtitle"
            defaultMessage="error"
          />
        </h3>
        <h2 >
          <FormattedMessage
            id="frontpage.title"
            defaultMessage="error"
          />
        </h2>
        <h3 className='frontpage_text'>
          <FormattedMessage
            id="frontpage.text"
            defaultMessage="error"
          />
        </h3>
      </div>
    </section>
  )
}

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

// Images and icons
import Verified from "../../assets/images/verified.svg";
import Down from "../../assets/images/down.svg";
import Monay from "../../assets/images/monay.svg";
import People from "../../assets/images/community.svg";
import Bag from "../../assets/images/bag.svg";
import Presents from "../../assets/images/presents.svg";

// Styles
import "../../styles/Home/Community.css";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgb(255, 227, 157)',
    color: 'black',
    maxWidth: 500,
    fontSize: '15px',
    border: 'none',
    padding: '20px'
  },
}));

export const Community = () => {
  return (
    <section id="ourdao" className='community_main'>
      <div className='community_front'>
        <h3 className='community_subtitle'>
          <FormattedMessage
            id="community.subtitle"
            defaultMessage="error"
          />
        </h3>
        <div className='community_title_content'>
          <img src={Verified} alt='Verified' width={60} />
          <h2>
            &nbsp;
            <FormattedMessage
              id="community.title"
              defaultMessage="error"
            />
            &nbsp;
            <span>
              <FormattedMessage
                id="community.title.span"
                defaultMessage="error"
              />
            </span>
          </h2>
        </div>
        <h3 className='community_text'>
          <FormattedMessage
            id="community.text"
            defaultMessage="error"
          />
        </h3>
        <div className='community_arrow'>
          <img src={Down} alt='Verified' />
        </div>
      </div>
      <div className='community_grid'>
        <div className='community_colum'>
          <div>
            <img src={Monay} />
            <h2>
              <FormattedMessage
                id="community.title.2"
                defaultMessage="error"
              />
              &nbsp;
              <span>
                <FormattedMessage
                  id="community.title.2.2"
                  defaultMessage="error"
                />
              </span>
            </h2>
          </div>
          <p>
            <FormattedMessage
              id="community.text.2"
              defaultMessage="error"
            />
            <br />
            <br />
            <span>
              <FormattedMessage
                id="community.text.2.1"
                defaultMessage="error"
              />
            </span>
            &nbsp;
            <FormattedMessage
              id="community.text.2.1.1"
              defaultMessage="error"
            />
            <br />
            <br />
            <span>
              <FormattedMessage
                id="community.text.2.2"
                defaultMessage="error"
              />
            </span>
            &nbsp;
            <FormattedMessage
              id="community.text.2.2.1"
              defaultMessage="error"
            />
            <br />
            <br />
            <span>
              <FormattedMessage
                id="community.text.2.3"
                defaultMessage="error"
              />
            </span>
            &nbsp;
            <FormattedMessage
              id="community.text.2.3.1"
              defaultMessage="error"
            />
          </p>
          <div>
            <img src={Presents} />
            <h2>
              <FormattedMessage
                id="community.title.4"
                defaultMessage="error"
              />
              &nbsp;
              <span>
                <FormattedMessage
                  id="community.title.4.2"
                  defaultMessage="error"
                />
              </span>
            </h2>
          </div>
          <p>
            <FormattedMessage
              id="community.text.4"
              defaultMessage="error"
            />
          </p>
        </div>
      </div>
    </section>
  )
}

import React from 'react';
import { FormattedMessage } from 'react-intl';

// Images and icons
import Team from '../../assets/images/team.png';
import Money from '../../assets/images/money.png';
import Safe from '../../assets/images/safe.png';
import Document from '../../assets/images/document.png';
import Chain from '../../assets/images/chain.svg';
import MechanismIcon from '../../assets/images/mechanims.svg';

// Styles
import "../../styles/Home/Mechanism.css";

export const Mechanism = () => {
  return (
    <section id="mechanism" className='mechanism_main'>
      <div className='mechanism_title_content'>
        <img src={MechanismIcon} alt='Mechanism' />
        <h2>
          <span>
            <FormattedMessage
              id="mechanism.title.span"
              defaultMessage="error"
            />
          </span>
          &nbsp;
          <FormattedMessage
            id="mechanism.title"
            defaultMessage="error"
          />
        </h2>
      </div>

      <div className='mechanism_grid'>

        <div>
          <div className='mechanism_card'>
            <img src={Team} alt='team' className='mechanism_card_img' />
            <p className='mechanism_card_title'>
              <FormattedMessage
                id="mechanism.card.text.1"
                defaultMessage="error"
              />
            </p>
            <p className='mechanism_card_title_2'>
              <FormattedMessage
                id="mechanism.card.text.1.span"
                defaultMessage="error"
              />
            </p>

            <div className='mechanism_text'>
              <p className='mechanism_text_p'>
                <FormattedMessage
                  id="mechanism.card.text.1.2"
                  defaultMessage="error"
                />
              </p>
            </div>
          </div>

          <div className='mechanism_card_2'>
            <img src={Safe} className='mechanism_card_img' alt='safe' />
            <p className='mechanism_card_title'>
              <FormattedMessage
                id="mechanism.card.text.3"
                defaultMessage="error"
              />
            </p>
            <p className='mechanism_card_title_2'>
              <FormattedMessage
                id="mechanism.card.text.3.span"
                defaultMessage="error"
              />
            </p>

            <div className='mechanism_text'>
              <p className='mechanism_text_p'>
                <FormattedMessage
                  id="mechanism.card.text.3.2"
                  defaultMessage="error"
                />
              </p>
            </div>
          </div>

          <div className='mechanism_card_2'>
            <img src={Safe} className='mechanism_card_img' alt='safe' />
            <p className='mechanism_card_title'>
              <FormattedMessage
                id="mechanism.card.text.5"
                defaultMessage="error"
              />
            </p>
            <p className='mechanism_card_title_2'>
              <FormattedMessage
                id="mechanism.card.text.5.span"
                defaultMessage="error"
              />
            </p>

            <div className='mechanism_text'>
              <p className='mechanism_text_p'>
                <FormattedMessage
                  id="mechanism.card.text.5.2"
                  defaultMessage="error"
                />
              </p>
            </div>
          </div>
        </div>

        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <img
            className='mechanism_chain'
            src={Chain}
            alt='chain'
            style={{
              marginTop: "250px"
            }}
          />
          <img
            className='mechanism_chain'
            src={Chain}
            alt='chain'
            style={{
              rotate: "90deg",
              marginBlock: "200px"
            }}
          />
          <img className='mechanism_chain' src={Chain} alt='chain' />
          <img
            className='mechanism_chain'
            src={Chain}
            alt='chain'
            style={{
              rotate: "90deg",
              marginBlock: "200px"
            }}
          />
        </div>

        <div style={{ marginTop: "300px" }} >
          <div className='mechanism_card'>
            <img src={Money} className='mechanism_card_img' alt='chain' />
            <p className='mechanism_card_title'>
              <FormattedMessage
                id="mechanism.card.text.2"
                defaultMessage="error"
              />
            </p>
            <p className='mechanism_card_title_2'>
              <FormattedMessage
                id="mechanism.card.text.2.span"
                defaultMessage="error"
              />
            </p>

            <div className='mechanism_text'>
              <p className='mechanism_text_p'>
                <FormattedMessage
                  id="mechanism.card.text.2.2"
                  defaultMessage="error"
                />
              </p>
            </div>
          </div>

          <div className='mechanism_card_2'>
            <img src={Document} className='mechanism_card_img' alt='document' />
            <p className='mechanism_card_title'>
              <FormattedMessage
                id="mechanism.card.text.4"
                defaultMessage="error"
              />
            </p>
            <p className='mechanism_card_title_2'>
              <FormattedMessage
                id="mechanism.card.text.4.span"
                defaultMessage="error"
              />
            </p>

            <div className='mechanism_text'>
              <p className='mechanism_text_p'>
                <FormattedMessage
                  id="mechanism.card.text.4.2"
                  defaultMessage="error"
                />
              </p>
            </div>
          </div>
        </div>

      </div>

      <div className='mechanism_colum'>
        <div className='mechanism_card'>
          <img src={Team} alt='team' className='mechanism_card_img' />
          <p className='mechanism_card_title'>
            <FormattedMessage
              id="mechanism.card.text.1"
              defaultMessage="error"
            />
          </p>
          <p className='mechanism_card_title_2'>
            <FormattedMessage
              id="mechanism.card.text.1.span"
              defaultMessage="error"
            />
          </p>

          <div className='mechanism_text'>
            <p className='mechanism_card_subtitle'>
              <FormattedMessage
                id="mechanism.card.title.1.2"
                defaultMessage="error"
              />
              &nbsp;
              <span>
                <FormattedMessage
                  id="mechanism.card.title.1.2.span"
                  defaultMessage="error"
                />
              </span>
            </p>
            <p className='mechanism_text_p'>
              <FormattedMessage
                id="mechanism.card.text.1.2"
                defaultMessage="error"
              />
            </p>
          </div>
        </div>

        <div className='mechanism_card'>
          <img src={Money} className='mechanism_card_img' alt='chain' />
          <p className='mechanism_card_title'>
            <FormattedMessage
              id="mechanism.card.text.2"
              defaultMessage="error"
            />
          </p>
          <p className='mechanism_card_title_2'>
            <FormattedMessage
              id="mechanism.card.text.2.span"
              defaultMessage="error"
            />
          </p>

          <div className='mechanism_text'>
            <p className='mechanism_card_subtitle'>
              <FormattedMessage
                id="mechanism.card.title.2.2"
                defaultMessage="error"
              />
              &nbsp;
              <span>
                <FormattedMessage
                  id="mechanism.card.title.2.2.span"
                  defaultMessage="error"
                />
              </span>
            </p>
            <p className='mechanism_text_p'>
              <FormattedMessage
                id="mechanism.card.text.2.2"
                defaultMessage="error"
              />
            </p>
          </div>
        </div>

        <div className='mechanism_card'>
          <img src={Safe} className='mechanism_card_img' alt='safe' />
          <p className='mechanism_card_title'>
            <FormattedMessage
              id="mechanism.card.text.3"
              defaultMessage="error"
            />
          </p>
          <p className='mechanism_card_title_2'>
            <FormattedMessage
              id="mechanism.card.text.3.span"
              defaultMessage="error"
            />
          </p>

          <div className='mechanism_text'>
            <p className='mechanism_card_subtitle'>
              <FormattedMessage
                id="mechanism.card.title.3.2"
                defaultMessage="error"
              />
              &nbsp;
              <span>
                <FormattedMessage
                  id="mechanism.card.title.3.2.span"
                  defaultMessage="error"
                />
              </span>
            </p>
            <p className='mechanism_text_p'>
              <FormattedMessage
                id="mechanism.card.text.3.2"
                defaultMessage="error"
              />
            </p>
          </div>
        </div>

        <div className='mechanism_card'>
          <img src={Document} className='mechanism_card_img' alt='document' />
          <p className='mechanism_card_title'>
            <FormattedMessage
              id="mechanism.card.text.4"
              defaultMessage="error"
            />
          </p>
          <p className='mechanism_card_title_2'>
            <FormattedMessage
              id="mechanism.card.text.4.span"
              defaultMessage="error"
            />
          </p>

          <div className='mechanism_text'>
            <p className='mechanism_card_subtitle'>
              <FormattedMessage
                id="mechanism.card.title.4.2"
                defaultMessage="error"
              />
              &nbsp;
              <span>
                <FormattedMessage
                  id="mechanism.card.title.4.2.span"
                  defaultMessage="error"
                />
              </span>
            </p>
            <p className='mechanism_text_p'>
              <FormattedMessage
                id="mechanism.card.text.4.2"
                defaultMessage="error"
              />
            </p>
          </div>
        </div>
      </div>
    </section >
  )
}

import React, { useState } from 'react';
import MensajesIngles from '../lang/en-UK.json';
import MensajesEspañol from '../lang/es-SP.json';
import { IntlProvider } from 'react-intl';

const langContext = React.createContext();

const LangProvider = ({ children }) => {
  let localePorDefecto;
  let mensajesPorDefecto;
  const lang = localStorage.getItem('lang');

  if (lang) {
    localePorDefecto = lang

    if (lang === 'es-SP') {
      mensajesPorDefecto = MensajesEspañol;
    } else {
      localePorDefecto = 'en-UK'
      mensajesPorDefecto = MensajesIngles
    }
  }
  else {
    localePorDefecto = 'en-UK'
    mensajesPorDefecto = MensajesIngles
  }

  const [mensajes, establecerMensajes] = useState(mensajesPorDefecto);
  const [locale, establecerLocale] = useState(localePorDefecto);

  const establecerLenguaje = (lenguaje) => {
    switch (lenguaje) {
      case 'es-SP':
        establecerMensajes(MensajesEspañol);
        establecerLocale('es-SP');
        localStorage.setItem('lang', 'es-SP');
        break;
      case 'en-UK':
        establecerMensajes(MensajesIngles);
        establecerLocale('en-UK');
        localStorage.setItem('lang', 'en-UK');
        break;
      default:
        establecerMensajes(MensajesIngles);
        establecerLocale('en-UK');
        localStorage.setItem('lang', 'en-UK');
    }
  }

  return (
    <langContext.Provider value={{ establecerLenguaje: establecerLenguaje }}>
      <IntlProvider locale={locale} messages={mensajes}>
        {children}
      </IntlProvider>
    </langContext.Provider>
  );
}

export { LangProvider, langContext };
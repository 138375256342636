import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import DataTutorials from './dataTutorials.jsx';
import { NavBar } from '../NavBar/NavBar.jsx';

// Styles
import '../../styles/Tutorials/tutorials.css';

export const Tutorials = () => {
  const [title, setTitle] = useState(null);
  const [text, setText] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    readTutorial(event.target.value)
  };

  const readTutorial = (value) => {
    if (value === "n/a") {
      setTitle(null);
      setText(null);
    } else {
      let { title, text } = DataTutorials.find((e) => e.id === value);
      setTitle(title);
      setText(text);
    }
  }

  return (
    <div className='tutorials_main'>
      <NavBar />
      <div className='tutorials_content'>
        <div className='tutorials_input'>
          <label>
            <FormattedMessage
              id="tutorials.select"
              defaultMessage="error"
            />
            <select
              className='tutorials_select'
              value={selectedOption}
              onChange={handleSelectChange}
            >
              <option value="n/a">
                <FormattedMessage
                  id="tutorials.select"
                  defaultMessage="error"
                />
              </option>
              <option value="ccm">
                <FormattedMessage
                  id="tutorials.ccm.title_1"
                  defaultMessage="error"
                />
                &nbsp;
                <FormattedMessage
                  id="tutorials.ccm.title_2"
                  defaultMessage="error"
                />
              </option>
              <option value="impusdt">
                <FormattedMessage
                  id="tutorials.impusdt.title_1"
                  defaultMessage="error"
                />
                &nbsp;
                <FormattedMessage
                  id="tutorials.impusdt.title_2"
                  defaultMessage="error"
                />
                &nbsp;
                <FormattedMessage
                  id="tutorials.impusdt.title_3"
                  defaultMessage="error"
                />
              </option>
              <option value="addbnb">
                <FormattedMessage
                  id="tutorials.addbnb.title_1"
                  defaultMessage="error"
                />
                &nbsp;
                <FormattedMessage
                  id="tutorials.addbnb.title_2"
                  defaultMessage="error"
                />
                &nbsp;
                <FormattedMessage
                  id="tutorials.addbnb.title_3"
                  defaultMessage="error"
                />
              </option>
              <option value="hbbnb">
                <FormattedMessage
                  id="tutorials.hbbnb.title_1"
                  defaultMessage="error"
                />
                &nbsp;
                <FormattedMessage
                  id="tutorials.hbbnb.title_2"
                  defaultMessage="error"
                />
              </option>
              <option value="hcbu">
                <FormattedMessage
                  id="tutorials.hcbu.title_1"
                  defaultMessage="error"
                />
                &nbsp;
                <FormattedMessage
                  id="tutorials.hcbu.title_2"
                  defaultMessage="error"
                />
              </option>
              <option value="cid">
                <FormattedMessage
                  id="tutorials.cid.title_1"
                  defaultMessage="error"
                />
                &nbsp;
                <FormattedMessage
                  id="tutorials.cid.title_2"
                  defaultMessage="error"
                />
              </option>
              <option value="add">
                <FormattedMessage
                  id="tutorials.add.title_1"
                  defaultMessage="error"
                />
                &nbsp;
                <FormattedMessage
                  id="tutorials.add.title_2"
                  defaultMessage="error"
                />
              </option>
            </select>
          </label>
        </div>
        {title !== null && text !== null ?
          <div className='tutorials'>
            <h3 className='tutorials_h3'>
              {title[0] &&
                <FormattedMessage
                  id={title[0]}
                  defaultMessage="error"
                />
              }
              {title[1] &&
                <span>
                  <FormattedMessage
                    id={title[1]}
                    defaultMessage="error"
                  />
                </span>
              }
              {title[2] &&
                <FormattedMessage
                  id={title[2]}
                  defaultMessage="error"
                />
              }
            </h3>
            <div className='tutorials_grid'>
              {text.map((e, index) => {
                const { img, text } = e;
                return (
                  <div className='tutorials_card' key={index}>
                    <div className='tutorials_text'>
                      <h3 className='tutorials_num'>
                        {index + 1}
                      </h3>
                      <p className='tutorials_p'>
                        <FormattedMessage
                          id={text[0]}
                          defaultMessage="error"
                        />
                        {text[1] &&
                          <span>
                            <FormattedMessage
                              id={text[1]}
                              defaultMessage="error"
                            />
                          </span>
                        }
                        {text[2] &&
                          <FormattedMessage
                            id={text[2]}
                            defaultMessage="error"
                          />
                        }
                      </p>
                    </div>
                    <div className='tutorials_grid_img'>
                      <img src={img[0]} className='tutorials_img' />
                      {img[1] &&
                        <img src={img[1]} className='tutorials_img' />
                      }
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          : null
        }
      </div>
    </div>
  )
}

import { Routes, Route } from "react-router-dom";
import { Dapp } from "./components/Dapp/Dapp";
import { Home } from "./components/Home/Home";
import { Tutorials } from "./components/Tutorials/Tutorials";
import { Rules } from "./components/Home/Rules";

function App() {
  return (
    <Routes>
      <Route path="dapp/:inviter" element={<Dapp />} />
      <Route path="dapp" element={<Dapp />} />
      <Route path="tutorials" element={<Tutorials />} />
      <Route path="rules" element={<Rules />} />
      <Route path="*" element={<Home />} />
    </Routes>
  )
}

export default App


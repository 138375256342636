import { FormattedMessage } from "react-intl"

// icons
import Rewars from "../../assets/images/rewars.svg";

// Styles
import "../../styles/Home/Membership.css"

export const Membership = () => {
  return (
    <section className="membership_main">
      <div className="membership_title">
        <img src={Rewars} style={{ marginRight: "20px" }} />
        <h2>
          <FormattedMessage
            id="membership.title.1"
            defaultMessage="error"
          />
          &nbsp;
          <span>
            <FormattedMessage
              id="membership.title.2"
              defaultMessage="error"
            />
          </span>
        </h2>
        <img src={Rewars} style={{ marginLeft: "20px" }} />
      </div>
      <p className="membership_text">
        <FormattedMessage
          id="membership.text.1"
          defaultMessage="error"
        />
      </p>
    </section>
  )
}

import React from 'react';
import { FormattedMessage } from 'react-intl';

// icons
import Monay from "../../assets/images/money_icon.svg";
import Hands from "../../assets/images/hands.svg";
import Dollar from "../../assets/images/dollar_sign.svg";

// Styles
import "../../styles/Home/Rewards.css";

export const Rewards = () => {
  return (
    <section className='rewards_main'>

      <div>
        <h2>
          <FormattedMessage
            id="rewards.title.1"
            defaultMessage="error"
          />
          &nbsp;
          <span>
            <FormattedMessage
              id="rewards.title.1.span"
              defaultMessage="error"
            />
          </span>
        </h2>
        <p>
          <FormattedMessage
            id="rewards.text.1.1"
            defaultMessage="error"
          />
        </p>
        <center>
          <img src={Monay} style={{ width: "150px" }} />
        </center>
      </div>

      <div>
        <h2>
          <FormattedMessage
            id="rewards.title.2"
            defaultMessage="error"
          />
          &nbsp;
          <span>
            <FormattedMessage
              id="rewards.title.2.span"
              defaultMessage="error"
            />
          </span>
        </h2>
        <p>
          <FormattedMessage
            id="rewards.text.2.1"
            defaultMessage="error"
          />
        </p>
        <center>
          <img src={Hands} style={{ width: "150px" }} />
        </center>
      </div>

      <div>
        <h2>
          <FormattedMessage
            id="rewards.title.3"
            defaultMessage="error"
          />
          &nbsp;
          <span>
            <FormattedMessage
              id="rewards.title.3.span"
              defaultMessage="error"
            />
          </span>
        </h2>
        <p>
          <FormattedMessage
            id="rewards.text.3.1"
            defaultMessage="error"
          />
        </p>
        <center>
          <img src={Dollar} style={{ width: "100px" }} />
        </center>
      </div>

    </section>
  )
}

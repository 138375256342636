// Components
import { NavBar } from "../NavBar/NavBar";
import { FrontPage } from "./FrontPage";
import { Community } from "./Community";
import { Membership } from "./Membership";
import { Mechanism } from './Mechanism';
import { ExclusiveCommunity } from './ExclusiveCommunity';
import { Rewards } from './Rewards';
import { FAQ } from "./FAQ";
import { Footer } from "../Footer/Footer";

// Styles
import "../../styles/Home/Home.css";

export const Home = () => {
  return (
    <main>
      <div className="home_frontpage">
        <NavBar />
        <FrontPage />
      </div>
      <Community />
      <Mechanism />
      <Membership />
      <Rewards />
      <ExclusiveCommunity />
      <FAQ />
      <Footer />
    </main>
  )
}

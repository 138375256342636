import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { BlockchainNumber, DatesPeriod, NumberFormat } from '../../widgets/Functions';

// Components
import { DataTreasury } from "./DataTreasury";

// Styles
import "../../styles/Dapp/Treasury.css";

export const Treasury = ({ signedIn, contributions }) => {
  const [data, setData] = useState({
    amount: 0,
    dateContribution: 0,
    lockPeriod: 0,
    name: ""
  });
  const [items, setItems] = useState([]);

  useEffect(
    () => {
      if (contributions.length === 0) setItems([]);
      else if (contributions[0].amount["_hex"] === '0x00') {
        setItems([]);
      }
      else {
        setItems(contributions);
      }
    }, [contributions]
  )

  return (
    <div className='treasury'>
      <div className='treasury_list'>
        {signedIn && items.length > 0 ?
          <table>
            <thead>
              <tr>
                <th>
                  <FormattedMessage
                    id="treasury.table.name"
                    defaultMessage="error"
                  />
                </th>
                <th className='treasury_col'>
                  <FormattedMessage
                    id="treasury.table.startdate"
                    defaultMessage="error"
                  />
                </th>
                <th className='treasury_col'>
                  <FormattedMessage
                    id="treasury.table.enddate"
                    defaultMessage="error"
                  />
                </th>
                <th className='treasury_col'>
                  <FormattedMessage
                    id="treasury.table.period"
                    defaultMessage="error"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="treasury.table.amount"
                    defaultMessage="error"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {items.map(
                (item, index) => {
                  const amountHex = item.amount["_hex"];
                  const amount = BlockchainNumber(amountHex);
                  const lockPeriod = Number(item.lockPeriodMonths["_hex"]);
                  const dateContributionHex = item.startTime["_hex"];
                  const dateContributionNumber = Number(dateContributionHex);
                  const dateContributionDate = moment.unix(dateContributionNumber).utc()["_d"];
                  const dateContribution = moment(dateContributionDate).format("YYYY/MM/DD");
                  const name = item.name;
                  const { dateStartTemp, dateFinalDate } = DatesPeriod(dateContribution, lockPeriod);

                  return (
                    <tr
                      key={index}
                      className='select'
                      onClick={
                        () =>
                          setData(
                            {
                              dateContribution,
                              lockPeriod,
                              amount,
                              name
                            }
                          )
                      }
                    >
                      <th>{name}</th>
                      <th className='treasury_col'>{dateStartTemp}</th>
                      <th className='treasury_col'>{dateFinalDate}</th>
                      <th className='treasury_col'>
                        {`${lockPeriod} `}
                        <FormattedMessage
                          id="treasury.table.months"
                          defaultMessage="error"
                        />
                      </th>
                      <th>{`$${NumberFormat(amount, 0)} USD`}</th>
                    </tr>
                  )
                }
              )}
            </tbody>
          </table>
          :
          <h2>
            <FormattedMessage
              id="treasury.nodata"
              defaultMessage="error"
            />
          </h2>
        }
      </div>
      <div className='treasury_data'>
        {signedIn && items.length > 0 ?
          <DataTreasury data={data} />
          :
          <h2>
            <FormattedMessage
              id="treasury.nodata"
              defaultMessage="error"
            />
          </h2>
        }
      </div>
    </div>
  )
}

// Images and icons
import Logo from "../../assets/images/ddh.svg";

// Styles
import "../../styles/Footer/Footer.css";

export const Footer = () => {
  return (
    <div className="footer">
      <img src={Logo} alt="Logo DAO" />
    </div>
  )
}

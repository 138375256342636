import React from 'react'
import { FormattedMessage } from 'react-intl'

// Images
import { LuDot } from "react-icons/lu";

// Styles
import "../../styles/Home/ExclusiveCommunity.css"

const items = [
  {
    title1: "exclusivecommunity.card.title.1",
    title2: "exclusivecommunity.card.title.1.span",
    texts: [
      "exclusivecommunity.card.text.1.1",
      "exclusivecommunity.card.text.1.2",
      "exclusivecommunity.card.text.1.3"
    ],
  },
  {
    title1: "exclusivecommunity.card.title.2",
    title2: "exclusivecommunity.card.title.2.span",
    texts: [
      "exclusivecommunity.card.text.2.1",
      "exclusivecommunity.card.text.2.2"
    ],
  },
  {
    title1: "exclusivecommunity.card.title.3",
    title2: "exclusivecommunity.card.title.3.span",
    texts: [
      "exclusivecommunity.card.text.3.1",
      "exclusivecommunity.card.text.3.2",
      "exclusivecommunity.card.text.3.3"
    ],
  },
]

export const ExclusiveCommunity = () => {
  return (
    <section className='exclusive_main'>
      <div className='exclusive_grid'>
        <div className='exclusive_colum' />
        <div>
          <h3 className='exclusive_subtitle'>
            <FormattedMessage
              id="exclusivecommunity.subtitle"
              defaultMessage="error"
            />
          </h3>
          <h2 className='exclusive_title'>
            <span>
              <FormattedMessage
                id="exclusivecommunity.title1.span"
                defaultMessage="error"
              />
            </span>
            &nbsp;
            <FormattedMessage
              id="exclusivecommunity.title1"
              defaultMessage="error"
            />
          </h2>
          <p className='exclusive_text_title'>
            <FormattedMessage
              id="exclusivecommunity.text"
              defaultMessage="error"
            />
          </p>
          <div>
            {items.map(
              (item, index) => {
                const { title1, title2, texts } = item;
                return (
                  <div key={index} style={{ marginTop: "60px" }}>
                    <div className='exclusive_card_title'>
                      <h4 className='exclusive_h4'>
                        <FormattedMessage
                          id={title1}
                          defaultMessage="error"
                        />
                        &nbsp;
                        <span>
                          <FormattedMessage
                            id={title2}
                            defaultMessage="error"
                          />
                        </span>
                      </h4>
                    </div>
                    {texts.map(
                      (text, index) => (
                        <div key={index} className='exclusive_text'>
                          <p>
                            <FormattedMessage
                              id={text}
                              defaultMessage="error"
                            />
                          </p>
                        </div>
                      )
                    )}
                  </div>
                )
              }
            )}
          </div>
        </div>
      </div>
      <p className='exclusive_text_p'>
        <FormattedMessage
          id="exclusivecommunity.text.2"
          defaultMessage="error"
        />
      </p>
    </section>
  )
}

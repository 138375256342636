import moment from 'moment';


// This feature cuts the wallet
function TrimWallet(wallet, short) {
  const DISCONNECTED_MESSAGE = "Disconnected";
  const LENGTH = wallet ? wallet.length : 0;

  if (LENGTH > 9) {
    if (short !== false)
      return `${wallet.slice(0, 5)}...${wallet.slice(-4)}`;
    else
      return `${wallet.slice(0, 8)}...${wallet.slice(-8)}`;
  }

  return DISCONNECTED_MESSAGE;
}

// Format the number and separate the thousands by comma
function RoundNumber(number) {
  const m = Number((Math.abs(number) * 100).toPrecision(25));
  const round = (Math.round(m) / 100) * Math.sign(number);

  return round;
};

// Format the number and separate the thousands by comma
function NumberFormat(number, money) {
  const round = RoundNumber(number);
  const str = round.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const res = str.join(".")

  if (money)
    return `$${res} USD`;

  return res;
};

// Dates period
function DatesPeriod(dateContribution, lockPeriod) {
  let month = moment(dateContribution).month();
  let year = moment(dateContribution).year();
  let day = moment(dateContribution).date();
  let dateStartTemp = moment().year(year).month(month).date(1);
  let addMonth = 1

  if (day > 18) addMonth = 2;

  dateStartTemp = moment(dateStartTemp).add(addMonth, 'months');
  dateStartTemp = moment(dateStartTemp).format("YYYY/MM/DD");

  let dateFinalDate = moment(dateStartTemp).add(lockPeriod, 'months');
  dateFinalDate = moment(dateFinalDate).format("YYYY/MM/DD");

  let diffTotal = moment(dateFinalDate).diff(moment(dateStartTemp), 'months');
  let today = moment();
  let diffToday = moment(today).diff(moment(dateStartTemp), 'months');

  let difRes = diffTotal - diffToday;

  return { difRes, diffToday, dateStartTemp, dateFinalDate }
}

// Dates period
function DatesPeriod36() {
  let month = moment().month();
  let year = moment().year();
  let dateStartTemp = moment().year(year).month(month).date(1);
  let addMonth = 1

  dateStartTemp = moment(dateStartTemp).add(addMonth, 'months');

  let dateFinalDate = moment().year(year).month(0).date(1);

  let monthTStart = moment(dateStartTemp).month();

  if (monthTStart < 5) {
    dateFinalDate = moment(dateFinalDate).add(3, 'years');
    dateFinalDate = moment(dateFinalDate).add(5, 'months');
  } else {
    dateFinalDate = moment(dateFinalDate).add(4, 'years');
  }

  let months = moment(dateFinalDate).diff(moment(dateStartTemp), 'months');
  dateStartTemp = moment(dateStartTemp).format("YYYY/MM/DD");
  dateFinalDate = moment(dateFinalDate).format("YYYY/MM/DD");
  return { dateStartTemp, dateFinalDate, months }
}

// 
function NumberBlockchain(number) {
  const fractions = String(process.env.REACT_APP_FRACTIONS).substring(1);
  return `${number}${fractions}`;
}

//
function BlockchainNumber(value) {
  const number = Number(value) / Number(process.env.REACT_APP_FRACTIONS);
  const roundNumber = RoundNumber(number);
  return roundNumber;
}

export {
  TrimWallet,
  NumberFormat,
  DatesPeriod,
  DatesPeriod36,
  NumberBlockchain,
  BlockchainNumber,
  RoundNumber
}
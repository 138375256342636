import React, { useContext } from "react"
import { langContext } from "../assets/context/langContext"

// Img
import es from "./img/esp.svg"
import uk from "./img/uk.svg"

// Styles
import "./styles/Lang.css"

export const Lang = () => {
  const language = useContext(langContext);
  return (
    <div className="buttons">
      <button onClick={() => language.establecerLenguaje('en-UK')}>
        <img src={uk} alt="USA" />
      </button>
      <button onClick={() => language.establecerLenguaje('es-SP')}>
        <img src={es} alt="Spain" />
      </button>
    </div>
  )
}

import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import { NavBar } from "../NavBar/NavBar";
import { Footer } from "../Footer/Footer";

// Styles
import "../../styles/Home/Rules.css"

export const Rules = () => {

  return (
    <main className='rules_main'>
      <div className='rules_header'>
        <NavBar />
        <h3 className='rules_subtitle'>
          <FormattedMessage
            id="rules.subtitle"
            defaultMessage="error"
          />
        </h3>
        <h2 className='rules_title'>
          <FormattedMessage
            id="rules.title"
            defaultMessage="error"
          />
        </h2>
        <p className='rules_text'>
          <FormattedMessage
            id="rules.text"
            defaultMessage="error"
          />
        </p>
      </div>
      <section className='rules_dots'>
        <div>
          <h4 className='rules_dots_h4'>
            <FormattedMessage
              id="rules.title.1"
              defaultMessage="error"
            />
          </h4>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.1.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.1.1.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.1.1.2"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.1.2"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.1.2.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.1.3"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.1.3.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.1.3.2"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.1.3.3"
              defaultMessage="error"
            />
          </p>
        </div>

        <div>
          <h4 className='rules_dots_h4'>
            <FormattedMessage
              id="rules.title.2"
              defaultMessage="error"
            />
          </h4>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.2.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.2.2"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.2.3"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.2.4"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.2.5"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.2.6"
              defaultMessage="error"
            />
          </p>
        </div>

        <div>
          <h4 className='rules_dots_h4'>
            <FormattedMessage
              id="rules.title.3"
              defaultMessage="error"
            />
          </h4>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.3.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.3.1.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.3.1.2"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.3.2"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.3.2.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.3.2.2"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.3.2.3"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.3.3"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.3.3.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.3.4"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.3.4.1"
              defaultMessage="error"
            />
          </p>
        </div>

        <div>
          <h4 className='rules_dots_h4'>
            <FormattedMessage
              id="rules.title.4"
              defaultMessage="error"
            />
          </h4>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.4.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.4.2"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.4.2.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.4.2.2"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.4.3"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.4.3.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.4.3.2"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.4.4"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.4.4.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.4.4.2"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.4.5"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.4.5.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.4.5.2"
              defaultMessage="error"
            />
          </p>
        </div>

        <div>
          <h4 className='rules_dots_h4'>
            <FormattedMessage
              id="rules.title.5"
              defaultMessage="error"
            />
          </h4>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.5.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.5.2"
              defaultMessage="error"
            />
          </p>
        </div>

        <div>
          <h4 className='rules_dots_h4'>
            <FormattedMessage
              id="rules.title.6"
              defaultMessage="error"
            />
          </h4>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.6.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.6.2"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.6.2.p1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.6.2.p2"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.6.2.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.6.2.1.p1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.6.2.2"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.6.2.2.p1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.6.2.3"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.6.2.3.p1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.6.2.3.p2"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.6.3"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.6.3.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.6.4"
              defaultMessage="error"
            />
          </p>
        </div>

        <div>
          <h4 className='rules_dots_h4'>
            <FormattedMessage
              id="rules.title.7"
              defaultMessage="error"
            />
          </h4>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.7.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.7.1.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.7.1.2"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.7.2"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.7.2.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.7.3"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.7.3.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.7.4"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.7.4.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.7.5"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.7.5.1"
              defaultMessage="error"
            />
          </p>
        </div>

        <div>
          <h4 className='rules_dots_h4'>
            <FormattedMessage
              id="rules.title.8"
              defaultMessage="error"
            />
          </h4>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.8.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.8.2"
              defaultMessage="error"
            />
          </p>
        </div>

        <div>
          <h4 className='rules_dots_h4'>
            <FormattedMessage
              id="rules.title.9"
              defaultMessage="error"
            />
          </h4>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.9.1"
              defaultMessage="error"
            />
          </p>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.9.2"
              defaultMessage="error"
            />
          </p>
        </div>

        <div>
          <h4 className='rules_dots_h4'>
            <FormattedMessage
              id="rules.title.10"
              defaultMessage="error"
            />
          </h4>
          <p className='rules_dots_p'>
            <FormattedMessage
              id="rules.text.10.1"
              defaultMessage="error"
            />
          </p>
        </div>

      </section>
      <Footer />
    </main>
  )
}

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// icons
import Ask from "../../assets/images/ask.svg";

// Styles
import "../../styles/Home/FAQ.css";

const items = [
  {
    title: "faq.title.1",
    text: "faq.text.1"
  },
  {
    title: "faq.title.2",
    text: "faq.text.2"
  },
  {
    title: "faq.title.3",
    text: "faq.text.3"
  },
  {
    title: "faq.title.4",
    text: "faq.text.4"
  },
  {
    title: "faq.title.5",
    text: "faq.text.5"
  },
]

export const FAQ = () => {

  const sliderSettings = {
    centerMode: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  return (
    <section id="faq" className='faq_main'>
      <h3>
        <FormattedMessage
          id="faq.subtitle"
          defaultMessage="error"
        />
      </h3>
      <div className='faq_title'>
        <img src={Ask} />
        <h2>
          <FormattedMessage
            id="faq.title"
            defaultMessage="error"
          />
          &nbsp;
          <span>
            <FormattedMessage
              id="faq.title.span"
              defaultMessage="error"
            />
          </span>
          &nbsp;
          <FormattedMessage
            id="faq.2.title"
            defaultMessage="error"
          />
        </h2>
      </div>
      <p>
        <FormattedMessage
          id="faq.text"
          defaultMessage="error"
        />
      </p>
      <div className='faq_content'>
        <Slider {...sliderSettings}>
          {items.map(
            (item, index) => {
              const { title, text } = item;
              return (
                <div key={index} className='faq_slider' >
                  <div className='faq_card'>
                    <h3>
                      <FormattedMessage
                        id={title}
                        defaultMessage="error"
                      />
                    </h3>
                    <p>
                      <FormattedMessage
                        id={text}
                        defaultMessage="error"
                      />
                    </p>
                  </div>
                </div>
              )
            }
          )
          }
        </Slider>
      </div>
    </section>
  )
}

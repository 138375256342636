import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import swal from 'sweetalert';

// Images and icons
import { GoDotFill } from "react-icons/go";

// Styles
import "../../styles/Dapp/ContractData.css";

const nameContract = process.env.REACT_APP_CONTRACT_NAME;

export const ContractData = ({ signedIn, stateContract, signInUser, wallet, inviterDefault }) => {
  const [inviter, setInviter] = useState('');

  useState(
    () => {
      if (inviterDefault !== undefined) setInviter(inviterDefault);
    }
  )

  const HandleInviter = (e) => {
    let value = e.target.value;
    setInviter(value)
  }

  const HandleSend = () => {
    if (inviter.length === 0)
      swal("Error!", "No wallet was entered", "error")
    else if (inviter.length === 0 || inviter.length < 20)
      swal("Error!", "Invalid wallet", "error")
    else if (inviter === wallet)
      swal("Error!", "The wallet cannot be the same", "error")
    else signInUser(inviter)
  }

  const HandleClipboard = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_DOMAIN}Dapp/${wallet}`)
      .then(() => {
        swal("Success!", "Your link was copied successfully", "info")
      })
      .catch(err => {
        swal("Error!", "your link was not copied", "error")
      })
  }

  return (
    <div className='contract_main' >
      <div>
        <h2>
          <FormattedMessage
            id="contractdata.title"
            defaultMessage="error"
          />
        </h2>
        <h3 className="section_dapp_h3">
          <FormattedMessage
            id="contractdata.label.version"
            defaultMessage="error"
          />
          <span>
            {nameContract}
          </span>
        </h3>
        <h3 className="section_dapp_h3">
          <FormattedMessage
            id="contractdata.wallet"
            defaultMessage="error"
          />
          <span>
            <GoDotFill
              className={signedIn ? "dotActive" : "dotInactive"}
            />
            {signedIn ?
              <FormattedMessage
                id="contractdata.wallet.active"
                defaultMessage="error"
              />
              :
              <FormattedMessage
                id="contractdata.wallet.inactive"
                defaultMessage="error"
              />
            }
          </span>
        </h3>
      </div>
      {!signedIn && stateContract &&
        <div>
          <div className='contract_input'>
            <p>
              <FormattedMessage
                id="contractdata.inviter"
                defaultMessage="error"
              />
            </p>
            <div className='contract_inviter'>
              <input
                defaultValue={inviter}
                onChange={HandleInviter}
              />
            </div>
          </div>
          <div className='contract_singin'>
            <button
              onClick={HandleSend}
            >
              <FormattedMessage
                id="dapp.signin"
                defaultMessage="error"
              />
            </button>
          </div>
        </div>
      }
      {signedIn && stateContract &&
        <div className='contract_ink'>
          <button
            onClick={HandleClipboard}
          >
            <FormattedMessage
              id="dapp.link"
              defaultMessage="error"
            />
          </button>
        </div>
      }
    </div >
  )
}

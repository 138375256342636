import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from 'react-chartjs-2';
import { FormattedMessage } from "react-intl";
import { NumberFormat, DatesPeriod } from "../../widgets/Functions";
import moment from "moment";

// Json
import DataContributions from "../../assets/json/dataContributions.json";

// Images and icons
import { GoDotFill } from "react-icons/go";

// Styles
import "../../styles/Dapp/DataTreasury.css"

ChartJS.register(ArcElement, Tooltip, Legend);

export const DataTreasury = ({ data }) => {
  const { amount, dateContribution, lockPeriod = 0, name } = data;
  const [dateStart, setDateStart] = useState("");
  const [dateFinal, setDateFinal] = useState("");
  const [total, setTotal] = useState(0);
  const [type, setType] = useState("");
  const [start, setStart] = useState(false);
  const [status, setStatus] = useState(true);
  const [contract, setContract] = useState(0);
  const [dataG, setDataG] = useState({
    labels: [`Progress: 0 months`, `Remaining: 0 months`],
    datasets: [
      {
        data: [0, 0],
        backgroundColor: [
          '#FBB03B',
          '#FBDA82'
        ],
        hoverBackgroundColor: [
          '#FBB03B',
          '#FBDA82'
        ]
      }
    ]
  });

  useEffect(
    () => {
      const {
        difRes,
        diffToday,
        dateStartTemp,
        dateFinalDate
      } = DatesPeriod(dateContribution, lockPeriod);
      const today = moment().format("YYYY/MM/DD");

      let res = {
        "months": 0,
        "percentage": 0,
        "type": 0
      }

      if (moment(dateStartTemp) <= moment(today)) setStart(true);
      else setStart(false);

      if (moment(dateFinalDate) < moment(today)) setStatus(false);
      else setStatus(true);

      if (moment(dateStartTemp) <= moment("2024/05/01")) {
        if (lockPeriod !== 0) res = DataContributions.find(e => e.months === lockPeriod);
        setTotal(amount * res.percentage);
        setType(res.type);
        setContract(0);
      } else {
        setType("");
        setTotal(amount);
        setContract(1);
      }

      setDateStart(dateStartTemp);
      setDateFinal(dateFinalDate);

      setDataG({
        labels: [`Progress: ${diffToday} months`, `Remaining: ${difRes} months`],
        datasets: [
          {
            data: [diffToday, difRes],
            backgroundColor: [
              '#FBB03B',
              '#FBDA82'
            ],
            hoverBackgroundColor: [
              '#FBB03B',
              '#FBDA82'
            ]
          }
        ]
      })

    }, [amount, dateContribution, lockPeriod]
  )

  return (
    <div className="data">
      <div className="data_colum">
        {amount === 0 ?
          <h2>
            <FormattedMessage
              id="datatreasury.select"
              defaultMessage="error"
            />
          </h2>
          :
          <>
            <div>
              <h4>
                <FormattedMessage
                  id="datatreasury.status"
                  defaultMessage="error"
                />
                <span>
                  <GoDotFill
                    className={status ? "dotActive" : "dotInactive"}
                  />
                  {status ?
                    <FormattedMessage
                      id="datatreasury.active"
                      defaultMessage="error"
                    />
                    :
                    <FormattedMessage
                      id="datatreasury.inactive"
                      defaultMessage="error"
                    />
                  }
                </span>
              </h4>
              <h5>
                <FormattedMessage
                  id="datatreasury.dividends"
                  defaultMessage="error"
                />
                <span>
                  {contract === 0 && `${NumberFormat(total, 1)}`}
                  {contract === 1 && `${NumberFormat(total * 1.5, 1)} - ${NumberFormat(total * 3, 1)}`}
                </span>
              </h5>
            </div>
            <div>
              <h3 className="data_colum_h3">
                <FormattedMessage
                  id="datatreasury.description"
                  defaultMessage="error"
                />
                <span>
                  {name}
                </span>
              </h3>
              <h3>
                <FormattedMessage
                  id="datatreasury.payment"
                  defaultMessage="error"
                />
                <span>
                  {type === 0 ?
                    <FormattedMessage
                      id="datatreasury.monthly"
                      defaultMessage="error"
                    />
                    :
                    <FormattedMessage
                      id="datatreasury.onepay"
                      defaultMessage="error"
                    />
                  }
                </span>
              </h3>
              <h3>
                <FormattedMessage
                  id="datatreasury.date"
                  defaultMessage="error"
                />
                <span>{dateContribution}</span>
              </h3>
              <h3>
                <FormattedMessage
                  id="datatreasury.startdate"
                  defaultMessage="error"
                />
                <span>{dateStart}</span>
              </h3>
              <h3>
                <FormattedMessage
                  id="datatreasury.enddate"
                  defaultMessage="error"
                />
                <span>{dateFinal}</span>
              </h3>
              <h3>
                <FormattedMessage
                  id="datatreasury.contribution"
                  defaultMessage="error"
                />
                <span>{`${NumberFormat(amount, 1)}`}</span>
              </h3>
            </div>
          </>
        }
      </div>
      {amount === 0 ?
        <div />
        :
        <div className="data_chart">
          {start ?
            <Doughnut data={dataG} />
            :
            <h3>
              <FormattedMessage
                id="datatreasury.nostart"
                defaultMessage="error"
              />
            </h3>
          }
        </div>
      }
    </div>
  )
}

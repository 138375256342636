import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { Link, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import swal from 'sweetalert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';

// Components
import { Account } from "./Account";
import { Lang } from "../../widgets/Lang.jsx";
import { Treasury } from "./Treasury";
import { Staking } from "./Staking";
import { ContractData } from "./ContractData";
import { NumberBlockchain, BlockchainNumber, RoundNumber } from "../../widgets/Functions.jsx";

// Images and icons
import Logo from "../../assets/images/ddh.svg";
import { GiExitDoor } from "react-icons/gi";
import { FaChalkboardTeacher } from "react-icons/fa";

// Assets
import ABI from '../../assets/json/abi_black.json';
import ABIINVEST from '../../assets/json/abi_invest.json';
import ABIUSDT from '../../assets/json/usdtAbi.json';

// Styles
import "../../styles/Dapp/Dapp.css";

const contractAddressUSDT = process.env.REACT_APP_CONTRACT_USDT;
const contractAddress = process.env.REACT_APP_CONTRACT;
const contractInvestmentAddress = process.env.REACT_APP_CONTRACT_INVEST;
const min = Number(process.env.REACT_APP_MIN_CONTRIBUTIONS);
const chain = Number(process.env.REACT_APP_CHAIN);
const chainHex = process.env.REACT_APP_CHAIN_HEX;
const ValueSingIn = Number(process.env.REACT_APP_SINGIN);

function MessageSingIn({ data, acction, close }) {
  const { value, inviter } = data;
  return (
    <div>
      <p className="message_title">
        <FormattedMessage
          id="dapp.signin.thankyou"
          defaultMessage="error"
        />
      </p>
      <div className="message_paragraph">
        <p>
          <FormattedMessage
            id="dapp.signin.consent"
            defaultMessage="error"
          />
        </p>
      </div>
      <div className="message_paragraph">
        <p>
          <FormattedMessage
            id="dapp.signin.p1"
            defaultMessage="error"
          />
        </p>
      </div>
      <div className="message_paragraph_num">
        <p>
          <FormattedMessage
            id="dapp.signin.n1"
            defaultMessage="error"
          />
        </p>
        <p>
          <FormattedMessage
            id="dapp.signin.n2"
            defaultMessage="error"
          />
        </p>
      </div>
      <div className="message_paragraph">
        <p>
          <FormattedMessage
            id="dapp.signin.p2"
            defaultMessage="error"
          />
        </p>
      </div>
      <div className="message_paragraph">
        <p>
          <FormattedMessage
            id="dapp.signin.note"
            defaultMessage="error"
          />
        </p>
      </div>
      <div className="message_buttons">
        <button className="message_buttons_deny" onClick={close}>
          <FormattedMessage
            id="dapp.button.deny"
            defaultMessage="error"
          />
        </button>
        <button onClick={() => acction(value, inviter)}>
          <FormattedMessage
            id="dapp.button.next"
            defaultMessage="error"
          />
        </button>
      </div>
    </div>
  )
};

function MessageAddInvest({ data, acction, close }) {
  const { invest, lockPeriodInMonths } = data;
  var name = "";
  return (
    <div>
      <p className="message_title">
        <FormattedMessage
          id="dapp.addinvest.title"
          defaultMessage="error"
        />
      </p>
      <div className="message_paragraph">
        <p>
          <FormattedMessage
            id="dapp.addinvest.name"
            defaultMessage="error"
          />
        </p>
        <input
          type='text'
          maxLength={30}
          onChange={(e) => name = e.target.value}
        />
      </div>
      <div className="message_paragraph">
        <p>
          <FormattedMessage
            id="dapp.addinvest.note"
            defaultMessage="error"
          />
        </p>
      </div>
      <div className="message_buttons">
        <button className="message_buttons_deny" onClick={close}>
          <FormattedMessage
            id="dapp.button.deny"
            defaultMessage="error"
          />
        </button>
        <button onClick={() => acction(invest, { lockPeriodInMonths, name })}>
          <FormattedMessage
            id="dapp.button.next"
            defaultMessage="error"
          />
        </button>
      </div>
    </div>
  )
};

export const Dapp = () => {
  const { inviter } = useParams();
  const [provider, setProvider] = useState(null);
  const [stateContract, setStateContract] = useState(true);
  const [stateNetwork, setStateNetwork] = useState(0);
  const [account, setAccount] = useState('');
  const [balance, setBalance] = useState(null);
  const [usdtBalance, setUsdtBalance] = useState(null);
  const [signedIn, setSignedIn] = useState(false);
  const [contract, setContract] = useState(null);
  const [contractInvest, setContractInvest] = useState(null);
  const [contributions, setContributions] = useState([])
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [process, setProcess] = useState("backdrop.addinvest");
  const [processMessage, setProcessMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    async function loadBlockchainData() {
      if (window.ethereum) {
        try {

          handleOpenBackdrop("backdrop.login");
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          setProvider(provider);

          await window.ethereum.request({ method: 'eth_requestAccounts' });

          const accounts = await provider.listAccounts();
          setAccount(accounts[0]);
          const network = await provider.getNetwork();
          if (network.chainId === chain) {
            // Contrato incial y de singin
            const contractInstance = new ethers.Contract(contractAddress, ABI, provider.getSigner());
            setStateNetwork(100);
            setContract(contractInstance);
            checkContractState(contractInstance);
            checkIsSignedIn(contractInstance, accounts[0]);
            // Contrato inversiones
            const contractInvestInstance = new ethers.Contract(contractInvestmentAddress, ABIINVEST, provider.getSigner());
            setContractInvest(contractInvestInstance);
            getInvestmentDetails(contractInstance, contractInvestInstance, accounts[0]);
          } else {
            handleOpenBackdrop("backdrop.changeChain");
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: chainHex }],
            });
          }
          window.ethereum.on('accountsChanged', () => window.location.reload());
          window.ethereum.on('chainChanged', () => window.location.reload());
          balanceWallet(provider, accounts[0])
        } catch (error) {
          handleOpenBackdrop("backdrop.bscnot");
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId: chainHex, // El ID de la red de BSC en hexadecimal
                chainName: 'BNB Smart Chain Mainnet',
                nativeCurrency: {
                  name: 'BNB',
                  symbol: 'BNB',
                  decimals: 18,
                },
                rpcUrls: ['https://bsc-dataseed.binance.org/'], // URL del nodo de BSC
                blockExplorerUrls: ['https://bscscan.com/'], // URL del explorador de bloques de BSC
              }],
            });
            setStateNetwork(100);
            await window.ethereum.request({
              method: 'wallet_watchAsset',
              params: {
                type: 'ERC20',
                options: {
                  address: contractAddressUSDT,
                  symbol: 'USDT',
                  decimals: 18,
                },
              },
            });
            handleCloseBackdrop();
          } catch (error) {

          }
        }
      } else {
        setStateNetwork(-1);
        handleCloseBackdrop();
        swal("Error!", `Metamask is not installed, you will need to install it before proceeding. Please download and install Metamask from the official website. Once installed, you can continue with the registration process.`, "error");
      }
    }

    loadBlockchainData();
  }, []);

  const addTokenUSDT = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: contractAddressUSDT,
            symbol: 'USDT',
            decimals: 18,
          },
        },
      });
    } catch (error) {
      swal("Warning!", "User rejected transaction", "warning");
    }
  }

  const balanceWallet = async (provider, account) => {
    try {
      handleOpenBackdrop("backdrop.checkcontractstate");
      const balance = await provider.getBalance(account);
      const etherBalance = ethers.utils.formatEther(balance);
      let contractInstanceUSDT = new ethers.Contract(contractAddressUSDT, ABIUSDT, provider.getSigner());
      const usdtBalance = await contractInstanceUSDT.balanceOf(account);
      setUsdtBalance(BlockchainNumber(usdtBalance));
      setBalance(RoundNumber(etherBalance));
    } catch (error) {
      handleCloseBackdrop();
    }
  };

  const checkContractState = async (contract) => {
    try {
      handleOpenBackdrop("backdrop.checkcontractstate");
      const result = await contract.paused();
      setStateContract(!result);
      handleCloseBackdrop();
    } catch (error) {
      setStateContract(true);
      handleCloseBackdrop();
    }
  };

  const checkIsSignedIn = async (contract, address) => {
    try {
      handleOpenBackdrop("backdrop.checkissignedin");
      const isSignedIn = await contract.hasPaid(address);
      setSignedIn(isSignedIn);
      handleCloseBackdrop();
    } catch (error) {
      setSignedIn(false);
      handleCloseBackdrop();
    }
  };

  const usdtApprove = async (value, data, contractNow) => {
    try {
      handleOpenBackdrop("backdrop.usdtapprove");
      if (value > usdtBalance) throw new Error('Transfer amount exceeds balance USDT.');
      let amount = NumberBlockchain(value);
      let contractInstanceUSDT = new ethers.Contract(contractAddressUSDT, ABIUSDT, provider.getSigner());
      const transaction = await contractInstanceUSDT.approve(contractNow, amount);
      await transaction.wait();
      if (processMessage === "signIn") signInUser(data);
      if (processMessage === "addInvest") addInvest(value, data);
    } catch (error) {
      console.error(error);
      handleCloseBackdrop();
      if (error.message.includes("user rejected transaction")) {
        swal("Warning!", "User rejected transaction", "warning");
      } else if (
        error.message.includes("invalid address") ||
        error.message.includes("network does not support ENS")
      ) {
        swal("Error!", "Invalid wallet", "error");
      } else if (
        error.message.includes("execution reverted: BEP40: transfer amount exceeds balance") ||
        error.message.includes("Transfer amount exceeds balance USDT.")
      ) {
        swal("Error!", "Transfer amount exceeds balance USDT.", "error");
      } else {
        swal(
          "Transaction error! (Usdt approve)",
          "This error was generated in the blockchain.",
          "error"
        );
      }
    }
  };

  const checkIsSignedInInviter = async (value, inviter) => {
    try {
      handleOpenBackdrop("backdrop.checkInviter");
      const singInviter = await contract.hasPaid(inviter);
      if (singInviter) usdtApprove(value, inviter, contractAddress);
      else {
        handleCloseBackdrop();
        swal("Error!", "Invalid inviter", "error");
      }
    } catch (error) {
      handleCloseBackdrop();
    }
  };

  const signInUser = async (inviterAddress) => {
    try {
      handleOpenBackdrop("backdrop.signinuser");
      const transaction = await contract.signIn(inviterAddress);
      await transaction.wait();
      balanceWallet(provider, account);
      checkIsSignedIn(contract, account);
      swal("Congratulations!", "Sign In was successful", "success");
    } catch (error) {
      console.error(error);
      handleCloseBackdrop();
      if (error.message.includes("user rejected transaction")) {
        swal("Warning!", "User rejected transaction", "warning");
      } else if (
        error.message.includes("invalid address") ||
        error.message.includes("network does not support ENS")
      ) {
        swal("Error!", "Invalid wallet", "error");
      } else {
        swal(
          "Transaction error! (signIn)",
          "This error was generated in the blockchain.",
          "error"
        );
      }
    }
  };

  const addInvest = async (invest, data) => {
    try {
      if (invest < min) throw new Error('The contribution is not the minimum');
      const { lockPeriodInMonths, name } = data;
      let amount = NumberBlockchain(invest);
      handleOpenBackdrop("backdrop.addinvest");
      const transaction = await contractInvest.invest(name, amount, lockPeriodInMonths);
      await transaction.wait();
      checkIsSignedIn(contract, account);
      balanceWallet(provider, account);
      getInvestmentDetails(contract, contractInvest, account);
    } catch (error) {
      handleCloseBackdrop();
      if (error.message.includes("user rejected transaction")) {
        swal("Warning!", "User rejected transaction", "warning");
      } else if (
        error.message.includes("invalid address") ||
        error.message.includes("network does not support ENS")
      ) {
        swal("Error!", "Invalid wallet", "error");
      } else if (
        error.message.includes("The contribution is not the minimum")
      ) {
        swal("Error!", `The minimum contribution amount is $${min} USDT.`, "error");
      } else if (
        error.message.includes("execution reverted: BEP40: transfer amount exceeds balance")
      ) {
        swal("Error!", "Transfer amount exceeds balance USDT.", "error");
      } else {
        swal(
          "Transaction error!",
          "This error was generated in the blockchain.",
          "error"
        );
      }
    }
  };

  const getInvestmentDetails = async (contract, contractInvest, address) => {
    try {
      handleOpenBackdrop("backdrop.getInvestment");
      const investmentDetails = await contract.getInvestmentDetails(address);
      const investmentDetails2 = await contractInvest.getInvestmentDetails(address);
      const combinedDetails = [...investmentDetails, ...investmentDetails2];
      setContributions(combinedDetails);
      handleCloseBackdrop();
    } catch (error) {
      setSignedIn(false);
      handleCloseBackdrop();
    }
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleOpenBackdrop = (process) => {
    setProcess(process);
    setOpenBackdrop(true);
  };

  const handleOpenModal = (data, process) => {
    if (process === "" || process === undefined || process === null)
      setOpenModal(false);
    else {
      setProcessMessage(process);
      setData(data);
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false)
  };

  return (
    <main className='main'>
      <section className='section_dapp'>
        <div className="controls">
          <div className="controls_buttons">
            <Link className="return" to="/">
              <GiExitDoor />
              &nbsp;
              <FormattedMessage id="dapp.controls.return" defaultMessage="error" />
            </Link>
          </div>
          <div className="controls_buttons">
            <Link className="return" to="/tutorials" target="_blank">
              <FaChalkboardTeacher />
              &nbsp;&nbsp;
              <FormattedMessage id="dapp.controls.tutorials" defaultMessage="error" />
            </Link>
          </div>
          <Lang />
        </div>
        <div className="header">
          <div className="header_colum">
            <Link className="logo" to="/">
              <img src={Logo} alt="Logo DAO" />
            </Link>
          </div>
          <div className="header_colum">
            <Account
              account={account}
              stateNetwork={stateNetwork}
              balance={balance}
              usdtBalance={usdtBalance}
              addTokenUSDT={() => addTokenUSDT()}
            />
          </div>
        </div>
        <div className="dapp">
          <div className="sidebar">
            <ContractData
              signedIn={signedIn}
              stateContract={stateContract}
              contract={contract}
              signInUser={(inviter) => handleOpenModal({ value: ValueSingIn, inviter }, "signIn")}
              wallet={account}
              inviterDefault={inviter}
            />
            <Staking
              addInvest={
                (lockPeriodInMonths, invest) => handleOpenModal({ invest, lockPeriodInMonths }, "addInvest")
              }
              type="dapp"
            />
          </div>
          <Treasury
            signedIn={signedIn}
            contributions={contributions}
          />
        </div>
        <Backdrop
          className="backdrop"
          style={{
            backgroundColor: 'rgb(0, 0, 0, .9)'
          }}
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <div>
            <CircularProgress
              style={{
                width: "60px",
                height: "60px",
                color: "rgb(251, 177, 61)"
              }}
              color="inherit"
            />
            <p className="backdrop_process">
              <FormattedMessage
                id={process}
                defaultMessage="error"
              />
            </p>
            <p className="backdrop_note">
              <FormattedMessage
                id="dapp.backdrop.warnig"
                defaultMessage="error"
              />
            </p>
          </div>
        </Backdrop>
        <Modal
          open={openModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: 'rgb(0, 0, 0, .9)'
          }}
        >
          <div className="modal">
            {processMessage === "signIn" &&
              <MessageSingIn
                data={data}
                close={handleCloseModal}
                acction={
                  (value, inviter) => {
                    handleCloseModal();
                    checkIsSignedInInviter(value, inviter);
                  }}
              />}
            {processMessage === "addInvest" &&
              <MessageAddInvest
                data={data}
                close={handleCloseModal}
                acction={
                  (invest, data) => {
                    handleCloseModal();
                    usdtApprove(invest, data, contractInvestmentAddress);
                  }}
              />}
          </div>
        </Modal>
      </section>
    </main>
  );
}
